
      @import '~backline-mixins/src/backline-mixins';
      @import 'settings';
    
.Dropdown {
  position: relative;
  display: inline-block;
  font-size: 14px;

  &.block {
    display: block;
  }
}

.DropdownList {
  border: 1px solid $color-grey40;
  background: #333;
  border-radius: spacing(1);
  padding: spacing(0.5);
  list-style: none;
}

.DropdownListItem {
  padding: 0 spacing(1.5);
  min-height: spacing(4);
  font-family: inherit;
  line-height: inherit;
  color: $color-white;
  text-align: left;
  transition: all 0.3s ease-out;
  border-radius: spacing(0.5);
  width: 100%;
  @include ellipsis();

  @include mq($breakpoint-tablet) {
    min-height: spacing(5);
  }

  &:hover,
  &:active,
  &:focus {
    cursor: pointer;
    transition-duration: 0.1s;
    outline: 0;
    background: #444;
    box-shadow: 0 0 0 1px #666;
  }

  &[disabled] {
    pointer-events: none;
  }

  &.selected {
    font-weight: 700;
    transform: translateY(-1px);
  }
}

.DropdownListOverlay {
  position: absolute;
  top: 0;
  left: 0;
  min-width: 100%;
  z-index: 1;
  box-shadow: 0 2px 5px rgba(#333, 0.5);

  .alignRight & {
    left: auto;
    right: 0;
  }

  li {
    opacity: 0;
    animation: dropdown-item-reveal 300ms 1 forwards $easing-bounce;
    &:first-child {
      opacity: 1;
    }
    @for $i from 1 through 20 {
      &:nth-child(#{$i}) {
        transform: translateY(#{($i - 1) * -5}px);
        animation-delay: #{($i - 1) * 30}ms;
      }
    }
  }
}

@keyframes dropdown-item-reveal {
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
