
      @import '~backline-mixins/src/backline-mixins';
      @import 'settings';
    
// .NavigationWrapper {}

// .NavigationList {}
.JP {
  font-family: $font-body-jp;
}
