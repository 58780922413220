
      @import '~backline-mixins/src/backline-mixins';
      @import 'settings';
    
.Main {
  flex: 1 0 auto;
  @include main-spacing;
}
.JP {
  font-family: $font-body-jp;
}
