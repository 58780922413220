
      @import '~backline-mixins/src/backline-mixins';
      @import 'settings';
    
.Background {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
}
