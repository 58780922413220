.ckeditor-text-150 {
  font-size: 1.5em;
  line-height: 0.9;
}

.ckeditor-text-140 {
  font-size: 1.4em;
  line-height: 0.9;
}

.ckeditor-text-130 {
  font-size: 1.3em;
  line-height: 0.9;
}

.ckeditor-text-120 {
  font-size: 1.2em;
  line-height: 0.9;
}

.ckeditor-text-110 {
  font-size: 1.1em;
  line-height: 1;
}

.ckeditor-text-90 {
  font-size: 0.9em;
}

.ckeditor-text-80 {
  font-size: 0.8em;
}

.ckeditor-text-70 {
  font-size: 0.7em;
}

.ckeditor-text-60 {
  font-size: 0.6em;
}

.ckeditor-citation {
  color: $color-grey50;
  font-size: 0.8em;
  font-style: italic;

  a {
    color: currentColor;
    font-weight: normal;
    text-decoration: underline;
  }
}
