
      @import '~backline-mixins/src/backline-mixins';
      @import 'settings';
    
.SearchOverlay {
  position: fixed;
  top: spacing(3);
  left: 50%;
  max-width: spacing(96);
  width: 100%;
  z-index: 20;
  animation: search-overlay-entrance $duration-long $easing-bounce 1 forwards;
}

.SearchOverlayControlWrapper {
  display: flex;
  background: $color-white;
  box-shadow: shadow(8);
  border-radius: spacing(1);
  border: 1px solid $color-grey80;
  overflow: hidden;
  font-size: spacing(2);
  margin-left: spacing(1);
  margin-right: spacing(1);
}

.SearchOverlayControl {
  flex: 1 0 auto;
  height: spacing(9);
  padding-left: spacing(4);
  border: 0;
  caret-color: $color-green;

  &:focus {
    outline: 0;
  }
}

.SearchOverlaySubmit {
  position: relative;
  padding: 0 spacing(4);

  svg {
    transition: fill $duration-standard $easing-standard;
  }

  &[disabled] {
    opacity: 0.4;
  }

  &::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(0.5);
    width: spacing(6);
    height: spacing(6);
    background: $color-green;
    border-radius: 50%;
    opacity: 0;
    transition: all $duration-standard $easing-bounce;
  }

  > * {
    position: relative;
  }

  &:hover,
  &:focus {
    outline: 0;

    &::before {
      opacity: 1;
      transform: translate(-50%, -50%) scale(1);
    }

    svg {
      fill: $color-white;
    }
  }
}

.SearchOverlayHeading {
  padding: spacing(1.5) spacing(4);
  color: $color-grey40;
}

.SearchOverlayList {
  list-style: none;
}

.SearchOverlayButton {
  background-color: $color-grey95;
  display: block;
  width: 100%;
  text-align: left;
  padding: spacing(1.5) spacing(4);

  &:hover,
  &:focus {
    outline: 0;
    font-weight: bold;
  }
}

@keyframes search-overlay-entrance {
  0% {
    transform: translateY(-120%) translateX(-50%);
  }
  100% {
    transform: translateY(0) translateX(-50%);
  }
}
