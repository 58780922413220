
      @import '~backline-mixins/src/backline-mixins';
      @import 'settings';
    
.ScrollNavItem {
  display: inline-block;
  margin: 0;
  vertical-align: top;
}

.ScrollNavItemLink {
  display: block;
  position: relative;
  color: currentColor;
  height: spacing(8);
  line-height: spacing(8);
  padding: 0 spacing(2);
  transition: color $duration-standard $easing-standard;
  font-size: spacing(1.75);
  text-decoration: none;

  // Hide a bold copy of the content text in an after element, so when the
  // regular text goes bold on hover, there is already enough space reserved
  // without shuffling the surrounding links about.
  // https://codepen.io/hexagoncircle/pen/WNrYPLo
  @supports (display: inline-flex) {
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    &::after {
      content: attr(data-text);
      content: attr(data-text) / '';
      height: 0;
      visibility: hidden;
      overflow: hidden;
      user-select: none;
      pointer-events: none;
      font-weight: 600;
      font-size: spacing(1.75) + 1;

      @include mq($breakpoint-tablet) {
        font-size: spacing(2) + 1;
      }

      @media speech {
        display: none;
      }
    }
  }

  @include mq($breakpoint-tablet) {
    font-size: spacing(2);
  }

  &:hover,
  &:focus,
  &:active,
  &.active {
    color: currentColor;
    font-weight: 600;
    transform: translateY(-1px);
    font-size: spacing(1.75) + 1;

    @include mq($breakpoint-tablet) {
      font-size: spacing(2) + 1;
    }
  }

  &.active {
    color: $color-primary;
  }
}
