
      @import '~backline-mixins/src/backline-mixins';
      @import 'settings';
    
@import '~backline-normalize/src/backline-normalize';
@import 'font-face';
@import 'ckeditor-custom';

// Let browsers know about available colour schemes
// https://drafts.csswg.org/css-color-adjust-1/#color-scheme-prop
// :root {
//   color-scheme: light dark;
// }

// Remove ALL animations/transitions by making them (almost) immediately skip
// to their finished state. May be overkill, but is a sensible start.
// https://css-tricks.com/revisiting-prefers-reduced-motion-the-reduced-motion-media-query/
@media screen and (prefers-reduced-motion: reduce), (update: slow) {
  * {
    animation-duration: 0.001ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.001ms !important;
  }
}

body {
  font-weight: normal;
  font-family: $font-body;
  font-size: 16px;
  line-height: 1.375; // 22px
  color: $color-black;

  @include mq($breakpoint-tablet) {
    font-size: 18px;
    line-height: 1.44444; // 26px
  }

  @include mq($breakpoint-desktop) {
    font-size: 20px;
    line-height: 1.3; // 26px
  }

  @include mq($breakpoint-desktopMedium) {
    font-size: 24px;
    line-height: 1.33334; // 32px
  }
}

::selection {
  background: $color-green;
  color: $color-white;
  text-shadow: none;
}
