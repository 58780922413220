
      @import '~backline-mixins/src/backline-mixins';
      @import 'settings';
    
.ButtonStandard {
  position: relative;
  padding: spacing(1.75) spacing(3);
  border-radius: spacing(3);
  background-color: $color-primary;
  color: $color-white;
  text-decoration: none;
  transition: all $duration-standard $easing-bounce;
  font-weight: 700;
  text-align: center;
  font-size: spacing(1.5);
  line-height: spacing(2);
  box-shadow: 0 0 0 0 inset $color-green, 0 0 0 0 $color-green;
  text-shadow: none;
  @include font-smoothing();

  @include mq($breakpoint-tablet) {
    font-size: spacing(1.75);
    line-height: spacing(2.5);
  }

  @include mq($breakpoint-desktop) {
    font-size: spacing(2);
  }

  &:hover,
  &:focus,
  &:active {
    background-color: shade($color-primary, 20%);
    color: $color-white;
    transition-duration: $duration-shortest;
    box-shadow: 0 0 0 spacing(0.25) inset $color-green,
      0 0 0 spacing(0.25) $color-green;
  }
}

.ButtonStandardContent {
  transition: opacity $duration-standard $easing-standard;
}

.ButtonStandardSpinner {
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.light {
  background-color: $color-white;
  color: $color-black;
  border: 4px solid transparent;
  box-shadow: 3px 3px 23px 4px $color-grey50;
  transition: border $duration-standard $easing-standard;
  &:hover,
  &:focus,
  &:active {
    box-shadow: 3px 3px 23px 4px $color-grey50;
    border-color: $color-grey80;
    background-color: $color-white;
    color: $color-grey50;
  }
}

.loading {
  pointer-events: none;

  .ButtonStandardContent {
    transition-duration: $duration-shortest;
    opacity: 0;
  }

  .ButtonStandardSpinner {
    opacity: 1;
  }
}

.accent {
  @supports (--css: variables) {
    background-color: var(--color-section-accent, $color-primary);
    box-shadow: 0 0 0 0 inset var(--color-section-accent, $color-primary),
      0 0 0 0 var(--color-section-accent, $color-primary);
    &:hover,
    &:focus,
    &:active {
      background-color: var(--color-section-accent, shade($color-primary, 20%));
      background-image: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0.3),
        rgba(0, 0, 0, 0.3)
      );
      box-shadow: 0 0 0 spacing(0.25) inset
          var(--color-section-accent, $color-primary),
        0 0 0 spacing(0.25) var(--color-section-accent, $color-primary);
    }
  }
}

.disabled {
  background-color: $color-grey80;
}

.small {
  padding: spacing(1.5) spacing(2.5);
  font-size: spacing(1.5);
  line-height: spacing(2);

  @include mq($breakpoint-tablet) {
    font-size: spacing(1.5);
  }

  @include mq($breakpoint-desktop) {
    font-size: spacing(1.75);
    line-height: spacing(2);
  }
}
