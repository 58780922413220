
      @import '~backline-mixins/src/backline-mixins';
      @import 'settings';
    
.Logo {
  position: relative;
  display: inline-block;
}

.LogoPart {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: contain;
}

.LogoFull {
  background-image: url('/public/image/structure/logo.svg');
}

.LogoTop {
  background-image: url('/public/image/structure/logo-top.svg');
}

.LogoEyes {
  background-image: url('/public/image/structure/logo-eyes.svg');
}

.LogoMiddle {
  background-image: url('/public/image/structure/logo-middle.svg');
}

.LogoBottom {
  background-image: url('/public/image/structure/logo-bottom.svg');
}

@media (hover: hover) {
  .interactive {
    .LogoMiddle,
    .LogoTop {
      transition: transform $duration-standard $easing-bounce;
    }
  }

  .interactive:hover,
  .interactive:focus {
    & .LogoMiddle {
      transform: scale(1.2) rotate(-5deg);
    }
    & .LogoTop {
      transform: scale(1.01) translateY(-2%);
    }
  }
}

.animated {
  .LogoEyes {
    animation: logo-blink 10s infinite;
  }
}

@keyframes logo-blink {
  0%,
  99% {
    opacity: 1;
  }
  99.1%,
  100% {
    opacity: 0;
  }
}
