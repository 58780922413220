
      @import '~backline-mixins/src/backline-mixins';
      @import 'settings';
    
.FooterBackground {
  height: 670px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #e4d884;
  @include mq($breakpoint-desktopLarge) {
    height: 200px;
  }
}

.FbSeaweed {
  position: absolute;
  bottom: calc(750px - 2vw);
  left: 20px;
  @include mq($breakpoint-desktopMedium) {
    bottom: calc(750px - 3vw);
  }
  @include mq($breakpoint-desktopLarge) {
    bottom: calc(750px - 4vw);
  }
}

.FbSeaweed2 {
  position: absolute;
  bottom: 750px;
  right: 20px;
}

.FbSubmarine {
  position: absolute;
  bottom: 35%;
  right: -80px;
}

.FbJellyfish {
  position: absolute;
  bottom: 55%;
  left: 30px;
  @include mq($breakpoint-desktop) {
    bottom: 45%;
  }
}

.FbCoral {
  position: absolute;
  bottom: 0;
  left: 0;
  @include mq($max: $breakpoint-tablet - 1) {
    max-width: 40vw;
  }
}

.FbCoral2 {
  position: absolute;
  bottom: 0;
  right: 0;
  @include mq($max: $breakpoint-tablet - 1) {
    max-width: 40vw;
  }
}

.FbBg {
  position: absolute;
  bottom: calc(800px - 43vw);
  left: 0;
  right: 0;
}
