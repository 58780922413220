
      @import '~backline-mixins/src/backline-mixins';
      @import 'settings';
    
.LanguageBanner {
  background-color: $color-black;
  color: $color-white;
  padding: spacing(1) spacing(2);
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: spacing(8);
  font-size: spacing(2);
  line-height: 1.3;
  text-align: center;
  flex-wrap: wrap;
  z-index: 20;

  @include mq($breakpoint-tabletLandscape) {
    flex-wrap: nowrap;
    text-align: left;
  }

  @include mq($breakpoint-desktop) {
    min-height: spacing(9);
    font-size: spacing(2.5);
  }

  a {
    color: $color-white;
    text-decoration: underline;
  }

  > * {
    margin-left: spacing(1);
    margin-right: spacing(1);
  }

  > *:first-child {
    margin-left: 0;
    flex-basis: 100%;
    margin-bottom: spacing(1);

    @include mq($breakpoint-tabletLandscape) {
      flex-basis: auto;
      margin-bottom: 0;
    }
  }

  > *:last-child {
    margin-right: 0;
  }
}
.JP {
  font-family: $font-body-jp;
}
.LanguageBannerDropdown {
  flex-basis: 280px;
}

.LanguageBannerDropdown,
.LanguageBannerButton {
  margin-bottom: spacing(1);

  @include mq($breakpoint-tabletLandscape) {
    margin-bottom: 0;
  }
}

.FreeResourcesFlag {
  flex: 1;
  align-self: center;
  padding: 15px 0 15px 15px;
}

.FreeResourcesFlagIcon {
  color: $color-pink;
}

.LanguageBannerContainer {
  display: flex;
  background-color: $color-nero;
}

.LanguageBannerWithFlag {
  flex: 30;
}
