$color-green: #3bae70;
$color-purple: #7251a1;
$color-pink: #e0296e;
$color-fuscia: #c5218c;
$color-nero: #1a1a1a;
$color-yellow: #f4d40e;
$color-black: hsl(0, 0%, 10%);
$color-black-op0: hsla(0, 0%, 10%, 0);
$color-grey20: hsl(0, 0%, 20%);
$color-grey25: hsl(0, 0%, 25%);
$color-grey40: hsl(0, 0%, 40%);
$color-grey50: hsl(0, 0%, 50%);
$color-grey75: hsl(0, 0%, 75%);
$color-grey80: hsl(0, 0%, 80%);
$color-grey85: hsl(0, 0%, 85%);
$color-grey95: hsl(0, 0%, 95%);
$color-white: hsl(0, 0%, 100%);

$color-primary: $color-green;
$color-primary-light: #1976d2;
$color-primary-dark: #0d47a1;

$color-status-success: #33691e;
$color-status-notice: #01579b;
$color-status-warning: #ff6f00;
$color-status-error: #b71c1c;

$color-status-list: (
  success: $color-status-success,
  notice: $color-status-notice,
  warning: $color-status-warning,
  error: $color-status-error
);
