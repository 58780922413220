
      @import '~backline-mixins/src/backline-mixins';
      @import 'settings';
    
.Header {
  position: relative;
  z-index: 10;
  background-color: $color-white;
  box-shadow: shadow(4);
  font-size: spacing(2);

  @include mq($breakpoint-desktop) {
    top: 0;
    position: sticky;
  }
}
.JP {
  font-family: $font-body-jp;
}
.HeaderInner {
  margin-left: auto;
  margin-right: auto;
  max-width: spacing(160);
  position: relative;
  z-index: 1;
}

.HeaderPrimary {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: spacing(2);
  padding-right: spacing(2);
  min-height: spacing(7);

  @include mq($breakpoint-tablet) {
    min-height: spacing(9);
  }

  @include mq($breakpoint-desktop) {
    padding-left: spacing(3);
    padding-right: 0;
  }

  @include mq($breakpoint-desktopMedium) {
    padding-right: spacing(2);
  }
}

.HeaderMobileButton {
  @include mq($breakpoint-tablet) {
    padding: spacing(1);
  }
  @include mq($breakpoint-desktop) {
    display: none;
  }
}

.CartMobile {
  color: #3bae70;
  text-decoration: none;
  padding-right: 0.5rem;
  @include mq($breakpoint-desktop) {
    display: none;
  }
}

.HeaderMobileRightContainer {
  display: flex;
  align-items: center;
}

.HeaderLogo {
  height: spacing(7);
  transform: translateY(spacing(0.5));

  @include mq($breakpoint-tablet) {
    transform: none;
  }

  @include mq($max: $breakpoint-desktopMedium - 1) {
    h1 > div {
      width: spacing(9) !important;
      height: spacing(9) !important;
    }
  }

  @include mq($max: $breakpoint-tablet - 1) {
    h1 > div {
      width: spacing(8) !important;
      height: spacing(8) !important;
    }
  }
}

.HeaderSecondary {
  padding: spacing(4) spacing(1);

  @include mq($max: $breakpoint-desktop - 1) {
    &.collapsed {
      display: none;
    }
  }
}

// .HeaderPrimaryNavigation {}

.HeaderSecondaryNavigation {
  margin-top: spacing(4);
}

.HeaderTertiaryNavigation {
  margin-top: spacing(4);
  padding-left: spacing(2);
  padding-right: spacing(2);
  text-align: center;

  a {
    width: 100%;
    max-width: spacing(50);
  }
}

@include mq($breakpoint-desktop) {
  .HeaderInner {
    display: flex;
    align-items: center;
  }

  // .HeaderPrimary {}

  .HeaderSecondary {
    flex: 1 0 auto;
    display: flex;
    align-items: center;
    padding: 0;
    padding-right: spacing(2);
  }

  .HeaderPrimaryNavigation {
    flex: 1 0 auto;
  }

  .HeaderSecondaryNavigation {
    margin-top: 2px;
  }

  .HeaderTertiaryNavigation {
    margin-top: 0;
  }
}

.HeaderSearchOverlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 20;
  animation: headeroverlay-blur $duration-long $easing-bounce 1 forwards;
}

.NavItemWithIcon {
  margin-right: 10px;
}

@keyframes headeroverlay-blur {
  0% {
    backdrop-filter: blur(0);
    background-color: rgba($color-white, 0);
  }
  100% {
    backdrop-filter: blur(1px);
    background-color: rgba($color-white, 0.3);
  }
}
