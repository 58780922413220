
      @import '~backline-mixins/src/backline-mixins';
      @import 'settings';
    
.Flag {
  display: inline-block;
  width: spacing(3);
  height: spacing(3);
  overflow: hidden;
  line-height: spacing(3);
  border-radius: 50%;
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
  vertical-align: bottom;
}
