
      @import '~backline-mixins/src/backline-mixins';
      @import 'settings';
    
.NavigationItem {
  list-style: none;
  margin-left: 0;
  position: relative;

  @include mq($breakpoint-desktop) {
    display: inline-block;
  }

  &.desktopOnly {
    @include mq($max: $breakpoint-desktop - 1) {
      display: none;
    }
  }
}

.NavigationItemLink {
  display: block;
  padding: spacing(1.25) spacing(2);
  text-decoration: none;
  color: $color-black;
  font-size: spacing(1.75);

  @include mq($breakpoint-desktop) {
    padding-left: spacing(0.75);
    padding-right: spacing(0.75);
  }

  @include mq($breakpoint-desktopMedium) {
    padding-left: spacing(1);
    padding-right: spacing(1);
  }

  &:hover,
  &:focus,
  &:active {
    color: $color-primary;
  }
}

.active {
  color: $color-primary;
}

.primary {
  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: spacing(2);
    right: spacing(2);
    border-bottom: 1px solid $color-grey80;

    @include mq($breakpoint-desktop) {
      content: none;
    }
  }

  .NavigationItemLink {
    font-size: spacing(2);
    padding: spacing(1.75) spacing(2);
    font-weight: 700;

    @include mq($breakpoint-desktop) {
      padding-left: spacing(1.5);
      padding-right: spacing(1.5);
    }
  }
}

.saveSpace {
  > :first-child {
    display: none;
  }

  @include mq($breakpoint-desktop) {
    > :first-child {
      display: none;
    }
    > :last-child {
      display: block;
    }
  }
}
