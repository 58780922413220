
      @import '~backline-mixins/src/backline-mixins';
      @import 'settings';
    
.ScrollNav {
  overflow: hidden;
  background-color: $color-black;
  color: $color-white;
  height: spacing(8);
  position: relative;
}

.ScrollNavList {
  text-align: center;
  white-space: nowrap;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  padding-left: spacing(2);
  padding-right: spacing(2);

  // Hide scrollbar on iOS/Android
  height: calc(100% + 30px);
  padding-bottom: 30px;
  &::-webkit-scrollbar,
  &::-webkit-scrollbar-track,
  &::-webkit-scrollbar-thumb {
    background-color: transparent;
  }
  &::-webkit-scrollbar {
    height: 0;
    width: 0;
  }
}

.ScrollNavIndicator {
  position: absolute;
  top: 0;
  bottom: 0;
  opacity: 0;
  pointer-events: none;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: opacity $duration-standard $easing-standard;
  padding: spacing(1);

  > * {
    opacity: 0.5;
  }

  &:first-child {
    left: 0;
    background-image: linear-gradient(
      to right,
      $color-black 50%,
      $color-black-op0
    );
    .overflowingLeft & {
      opacity: 1;
    }
  }

  &:last-child {
    right: 0;
    background-image: linear-gradient(
      to left,
      $color-black 50%,
      $color-black-op0
    );
    .overflowingRight & {
      opacity: 1;
    }
  }
}
