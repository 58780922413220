
      @import '~backline-mixins/src/backline-mixins';
      @import 'settings';
    
.A11yNavigation {
  a {
    position: absolute;
    top: -200px;
    clip: rect(1px, 1px, 1px, 1px);
    overflow: hidden;
    height: 0;
    background-color: $color-black;
    color: $color-white;
    z-index: 1000;
    padding: 1em;

    &:focus {
      clip: auto;
      top: 0;
      overflow: visible;
      height: auto;
      outline: 0;
    }
  }
}
