$main-spacing-mobile: spacing(3);
$main-spacing-tablet: spacing(4);
$main-spacing-desktop: spacing(6);

@mixin main-spacing() {
  padding-top: $main-spacing-mobile;
  padding-bottom: $main-spacing-mobile;

  @include mq($breakpoint-tablet) {
    padding-top: $main-spacing-tablet;
    padding-bottom: $main-spacing-tablet;
  }

  @include mq($breakpoint-desktopMedium) {
    padding-top: $main-spacing-desktop;
    padding-bottom: $main-spacing-desktop;
  }
}

@mixin main-spacing-reverse-top() {
  margin-top: $main-spacing-mobile * -1;

  @include mq($breakpoint-tablet) {
    margin-top: $main-spacing-tablet * -1;
  }

  @include mq($breakpoint-desktopMedium) {
    margin-top: $main-spacing-desktop * -1;
  }
}

@mixin main-spacing-reverse-bottom() {
  margin-bottom: $main-spacing-mobile * -1;

  @include mq($breakpoint-tablet) {
    margin-bottom: $main-spacing-tablet * -1;
  }

  @include mq($breakpoint-desktopMedium) {
    margin-bottom: $main-spacing-desktop * -1;
  }
}
