@font-face {
  font-family: 'Sweater School';
  src: url('/public/font/SweaterSchoolRg-Regular/font.woff2') format('woff2'),
    url('/public/font/SweaterSchoolRg-Regular/font.woff') format('woff');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Sweater School';
  src: url('/public/font/SweaterSchoolRg-Bold/font.woff2') format('woff2'),
    url('/public/font/SweaterSchoolRg-Bold/font.woff') format('woff');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
