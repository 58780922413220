
      @import '~backline-mixins/src/backline-mixins';
      @import 'settings';
    
.JPLogo {
  max-width: 88px;
  width: 100%;
  img {
    width: 100%;
    height: auto;
  }
}
