
      @import '~backline-mixins/src/backline-mixins';
      @import 'settings';
    
.IconButton {
  vertical-align: middle;
  height: spacing(6);
  line-height: spacing(6);
  min-width: spacing(6);
  white-space: nowrap;
  display: inline-block;

  &:focus,
  &:hover {
    text-decoration: none;
    outline: 0;
  }
}

.IconButtonInner {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;

  svg {
    vertical-align: top;
  }
}

.IconButtonText {
  margin-left: spacing(0.5);
}

.rounded {
  border-radius: 50%;
}

.solid {
  color: $color-white;
  background-color: $color-primary;
  transition: all $duration-standard $easing-standard;

  &:focus,
  &:hover,
  &:active {
    color: $color-white;
    background-color: shade($color-primary, 20%);
    transition-duration: $duration-shortest;
  }
}

.light {
  color: $color-black;
  background-color: $color-white;

  &:focus,
  &:hover,
  &:active {
    color: $color-black;
    background-color: $color-grey85;
  }
}

.large {
  height: spacing(11);
  min-width: spacing(11);
}

.small {
  height: spacing(3);
  line-height: spacing(3);
  min-width: spacing(3);
}

.increaseHitArea {
  position: relative;

  &::after {
    content: '';
    position: absolute;
    top: spacing(-2);
    right: spacing(-2);
    bottom: spacing(-2);
    left: spacing(-2);
  }
}

.hoverGrow {
  transition: all $duration-long $easing-bounce;

  &:hover,
  &:focus {
    transform: scale(1.3) rotate(-3deg);
    background-color: $color-white;
  }
}
